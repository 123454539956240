import React from 'react';
import { Link, graphql } from 'gatsby';
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from '../components/layout';
import Head from '../components/head';

export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter:{ slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        date
        slug
      }
    }
  }
`;

const Blog = props => {
  const { body, frontmatter: { title, date } } = props.data.mdx;
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric'};
  const locale = "en-US";
  const formattedDate = new Date(date).toLocaleDateString(locale, dateOptions);
  return (
    <Layout>
      {/* purgecss: pre code */}
      <article className="markdown mb-10 px-5">
        <Head title={title} />
        <div className="flex flex-col items-center pb-6">
          <p className="text-gray-500 font-semibold" >{formattedDate}</p>
          <h1>{title}</h1>
        </div>
        <MDXRenderer className="py-4">{body}</MDXRenderer>
      </article>
      <div className="border-t border-gray-500 py-6 flex justify-center">
        <Link to="/blog" className="flex items-center text-lg text-teal-500 hover:text-teal-800 font-semibold">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-current" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clipRule="evenodd" />
          </svg>
          <span className="pl-2">
            Back to the blog
          </span>
        </Link>
      </div>
    </Layout>
  );
}

export default Blog;

